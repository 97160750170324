export const EMAIL_MESSAGES = [
  `"These are the times to grow our souls. Each of us is called upon to embrace the conviction that despite the powers and principalities bent on commodifying all our human relationships, we have the power within us to create the world anew."`,
  `"The most common way people give up their power is by thinking they don't have any."`,
  `"Be hopeful, be optimistic. Our struggle is not the struggle of a day, a week, a month, or a year, it is the struggle of a lifetime. Never, ever be afraid to make some noise and get in good trouble, necessary trouble."`,
  `"Love isn't just something you feel. It's something you do everyday when you go out and pick the paper and bottles scattered the night before on the corner, when you stop and talk to a neighbor, … when you never stop believing that we can all be more than what we are. In other words, Love isn't about what we did yesterday; it's about what we do today and tomorrow and the day after."`,
  `"We are caught in an inescapable network of mutuality, tied in a single garment of destiny. Whatever affects one directly, affects all indirectly."`,
  `"All that you touch, you change. All that you change, changes you. The only lasting truth is change."`,
  `"If we are going to heal, let it be glorious."`,
  `"I am here and so are you. And we matter. We can change things."`,
  `"We need in every community, a group of angelic troublemakers."`,
  `"That person who helps others simply because it should or must be done, and because it is the right thing to do, is indeed without a doubt, a real superhero."`,
  `"Each of us has a unique part to play in the healing of the world."`,
  `"From what we get, we can make a living; what we give, however, makes a life."`,
  `"Your ordinary acts of love and hope point to the extraordinary promise that every human life is of inestimable value."`,
  `"I have found among its other benefits, giving liberates the soul of the giver."`,
  `"I like charitable people and like to think of myself as charitable, as being of a generous heart and a giving nature - of being a friend indeed to anyone in need."`,
  `"But to feel the affection that comes from giving is something still greater and more beautiful because it widens out the boundaries of our being, and unites all living things."`,
  `"Remember that consciousness is power. Tomorrow's world is yours to build."`,
  `"I slept and dreamt that life was joy. I awoke and saw that life was service. I acted and behold, service was joy."`,
  `"A single act of kindness throws out roots in all directions, and the roots spring up and make new trees."`,
  `"Wage love."`,
  `"Beauty was not simply something to behold; it was something one could do."`,
  `"After all, a person is herself, and others. Relationships chisel the final shape of one's being. I am me, and you."`,
  `"Not everything that is faced can be changed, but nothing can be changed until it is faced."`,
  `"Life's most persistent and urgent question is, 'What are you doing for others?'"`,
  `"In democratic countries knowledge of how to combine is the mother of all other forms of knowledge; on its progress depends that of all the others."`,
  `"What the hands do, the heart learns."`,
  `"Freedom is bound in struggle. And in running Not winning and losing. Freedom happens in our souls. Before we can speak it in a song, chant, or a hashtag. Freedom is a decision, Not just an outcome."`,
  `"We are each other's harvest; we are each other's business; we are each other's magnitude and bond."`,
  `"What is needed is a realization that power without love is reckless and abusive and that love without power is sentimental and anemic. Power at its best is love implementing the demands of justice. Justice at its best is power correcting everything that stands against love."`,
];

export const EMAIL_AUTHORS = [
  'Grace Lee Boggs',
  'Alice Walker',
  'Rep. John Lewis (GA)',
  'Grace Lee Boggs',
  'Martin Luther King Jr., Letter from a Birmingham Jail',
  'Octavia Butler',
  'Warsan Shire',
  'Ella Baker',
  'Bayard Rustin',
  'Stan Lee',
  'Marianne Williamson',
  'Arthur Ashe',
  'Desmond Tutu',
  'Maya Angelou, The Sweetness of Charity',
  'Maya Angelou, The Sweetness of Charity',
  'Pablo Neruda',
  'Yuri Kochiyama',
  'Rabindranath Tagore',
  'Amelia Earhart',
  'Charity Hicks',
  'Toni Morrison, The Bluest Eye',
  'N.K. Jemisin',
  'James Baldwin',
  'Martin Luther King, Jr.',
  'Alexis de Tocqueville, Democracy in America',
  'Movement Generation',
  'Derecka Purnell',
  'Gwendolyn Brooks, “Paul Robeson” (1971)',
  'Martin Luther King, Jr., from Where Do We Go From Here:Chaos or Community? (1967)',
];

export const EMAIL_QUOTES = EMAIL_MESSAGES.map((message, index) => ({
  message,
  author: EMAIL_AUTHORS[index],
}));
