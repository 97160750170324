import styled, { css } from 'styled-components';

import { BaseButton } from 'src/client/components/Button/styles';
import { LABEL_1_MED_2 } from 'src/client/constants/text';

type ImageCardProps = {
  $isSelected: boolean;
  $isLoading: boolean;
};

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 125px);

  & > span {
    ${LABEL_1_MED_2}
    margin-top: 16px;
    grid-column: span 3;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(2, 125px);
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;
`;

export const ImageCard = styled.div<ImageCardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 109px;

  border: 1px #cfd1d5 solid;
  border-radius: 8px;

  cursor: pointer;

  ${(props) =>
    props.$isLoading &&
    css`
      pointer-events: none;
    `}
`;

export const StyledImage = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const SelectLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000000d9;
  font-size: 14px;
  cursor: pointer;

  & > * {
    ${LABEL_1_MED_2}
  }
`;

export const RadioInput = styled.input`
  margin: 0;
  cursor: pointer;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 32px;
`;

export const DeleteButton = styled(BaseButton)`
  color: ${(props) => props.theme.colors.red6};

  :hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.red6};
  }

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.red1};
  }

  :disabled,
  :disabled:hover {
    background: white;
  }
`;
