import { rem } from 'polished';

import styled, { css } from 'styled-components';

import { FlexCenter, GalleryTextArea } from 'src/client/components';

import { EditLabel, OptionsGroup } from '../../styles';

export type InputContainerProps = {
  isEditing: boolean;
};

type OptionsGroupProps = {
  isHidden: boolean;
};

type AuthorTextProps = InputContainerProps & {
  isAdjectiveSectionVisible: boolean;
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const QuoteTextInputContainer = styled.div`
  width: 100%;
  width: 850px;
  display: flex;
  flex-direction: column;
`;

export const StyledEditLabel = styled(EditLabel)<OptionsGroupProps>`
  && {
    ${(props) =>
      props.isHidden &&
      css`
        color: ${(props) => props.theme.colors.gray8};
        background: ${(props) => props.theme.colors.gray5};
      `}
  }
`;

export const InputContainer = styled.div<
  InputContainerProps & OptionsGroupProps
>`
  padding: 10px;
  background-color: transparent !important;
  outline: none;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.black};

  border: ${(props) =>
    props.isEditing ? `1px solid ${props.theme.colors.border3}` : 'inherit'};

  &:hover,
  &:focus {
    border: ${(props) =>
      props.isEditing ? `1px solid ${props.theme.colors.border3}` : 'inherit'};
    box-shadow: none;
  }

  ${(props) =>
    !props.isEditing &&
    css`
      border: none;
      padding: 0;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`;

export const StyledSpinnerContainer = styled(FlexCenter)`
  height: 240px;
`;

const noBorderStyles = `
  overflow: hidden;
  border: none;

  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const QuoteTextArea = styled(GalleryTextArea)`
  font-family: ${(props) => props.theme.fontFamilies.vollkorn};
  font-size: ${rem('24px')};
  line-height: 130%;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  text-align: center;

  padding-bottom: 0px !important;
  margin-bottom: 14px;

  &::placeholder {
    color: ${(props) => props.theme.colors.darkHighEmphasis};
  }

  &:focus::placeholder {
    color: transparent;
  }

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('40px')};
  }

  ${noBorderStyles}
`;

const authorTextBaseStyles = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')} !important;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const AuthorTextArea = styled(GalleryTextArea)<AuthorTextProps>`
  ${authorTextBaseStyles}
  ${noBorderStyles}

  &::placeholder {
    color: ${(props) => props.theme.colors.darkHighEmphasis};
  }

  &:focus::placeholder {
    color: transparent; // or you can use display: none; if supported
  }

  text-align: center;
  width: 100%;
  padding-bottom: 0 !important;
  margin-bottom: 40px;

  ${(props) =>
    !props.isEditing &&
    !props.isAdjectiveSectionVisible &&
    css`
      margin-bottom: 80px;

      @media ${(props) => props.theme.screen.md} {
        margin-bottom: 128px;
      }
    `}
`;

export const AuthorContainer = styled.div`
  position: relative;
`;

export { OptionsGroup };
