import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';

import { GALLERY_TITLE_END_TEXT } from 'src/commons/constants/gallery';
import { getLastLetter } from 'src/commons/utils/StringUtils';

import * as S from './styles';

type Props = {
  value?: string;
  onChange?: (value: string) => Promise<void>;
};

function EditableGalleryTitleInput(props: Props) {
  const { onChange, value } = props;

  const ownerNameLastLetter = getLastLetter(value as string);
  const possessive = ownerNameLastLetter === 's' ? '’' : '’s';

  const [suffixText, setSuffixText] = useState<string>(possessive);
  const [inputWidthReferenceValue, setInputWidthReferenceValue] = useState<
    undefined | string
  >(value);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputWidthReference = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputWidthReferenceValue(value);

    if (isEmpty(value)) {
      setInputWidthReferenceValue('Name');
    }
  }, [value]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setInputWidthToInputWidthReference();
    });

    if (inputWidthReference.current) {
      observer.observe(inputWidthReference.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [inputWidthReference]);

  function setInputWidthToInputWidthReference() {
    if (inputWidthReference.current && inputRef.current) {
      inputRef.current.style.width = `${inputWidthReference.current?.offsetWidth}px`;
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;

    if (getLastLetter(inputValue) === 's') {
      setSuffixText('’');
    } else {
      setSuffixText('’s');
    }

    if (onChange) {
      onChange(inputValue);
    }
  }

  function handleContainerClick() {
    inputRef.current?.focus();
  }

  return (
    <S.Container data-cy="intro-title-edit-box" onClick={handleContainerClick}>
      <input
        data-cy="owner-name-input"
        placeholder="Name"
        ref={inputRef}
        value={value}
        onChange={handleChange}
      />
      <S.InputWidthReference
        data-cy="gallery-title-input-width-reference"
        ref={inputWidthReference}
      >
        {inputWidthReferenceValue}
      </S.InputWidthReference>
      <S.SuffixText>{suffixText}</S.SuffixText>
      <S.EndText data-cy="intro-title-edit-end-text">
        {GALLERY_TITLE_END_TEXT}
      </S.EndText>
    </S.Container>
  );
}

export default EditableGalleryTitleInput;
