import { Form, FormInstance } from 'antd';
import React from 'react';

import {
  GALLERY_THEME_COLOR_VALUES,
  GALLERY_THEME_COLORS,
  GalleryColor,
} from 'src/commons/constants/gallery';

import * as S from './styles';

type Props = {
  isLoading: boolean;
  themeColorForm: FormInstance<any>;

  handleSelectThemeColor: (color: GalleryColor) => void;
};

function ThemeColorPicker(props: Props) {
  const { isLoading, themeColorForm, handleSelectThemeColor } = props;

  const themeColor = themeColorForm.getFieldValue('themeColor');

  function handleSelect(color: GalleryColor) {
    handleSelectThemeColor(color);
  }

  return (
    <S.AccentColorPickerContainer isLoading={isLoading}>
      <span>Accent color</span>

      <Form.Item noStyle name="themeColor">
        {GALLERY_THEME_COLORS.map((color) => (
          <S.AccentColor
            color={GALLERY_THEME_COLOR_VALUES[color]}
            isDisabled={isLoading}
            isSelected={themeColor === color}
            key={color}
            onClick={() => handleSelect(color)}
          />
        ))}
      </Form.Item>
    </S.AccentColorPickerContainer>
  );
}

export default ThemeColorPicker;
