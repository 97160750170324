import React from 'react';

import {
  GALLERY_THEME_COLOR_VALUES,
  GalleryColor,
} from 'src/commons/constants/gallery';

import { getCurrentYear } from 'src/commons/utils/DateUtils';

import * as S from './styles';

type Props = {
  themeColor: GalleryColor;
};

function FooterSection(props: Props) {
  const { themeColor } = props;

  return (
    <S.FooterSection color={GALLERY_THEME_COLOR_VALUES[themeColor]}>
      <S.FooterText>© Giving Side {getCurrentYear()}</S.FooterText>
    </S.FooterSection>
  );
}

export default FooterSection;
