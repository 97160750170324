import { Modal } from 'antd';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Button, Text } from 'src/client/components';
import { minimalisticScrollBar } from 'src/client/components/StyledCommon';

import { LABEL_2_REG_2 } from 'src/client/constants/text';

import {
  StyledMenu,
  SeparatorPickerContainer,
  SeparatorColorSection,
  SeparatorPreviewContainer,
} from 'src/client/pages/DonorGallery/components/AdjectiveSection/styles';

import { EditLabel, OptionsGroup } from '../../styles';
import Shape from '../GalleryShapeInput/components/Shape';

type MenuItemProps = {
  isSelected: boolean;
  'data-cy-selected': string;
};

type AdjectiveContainerProps = {
  isPreview: boolean;
};

type OptionsGroupProps = {
  isHidden: boolean;
};

export const GivingAdjectiveSection = styled.section`
  margin-top: 80px;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.screen.md} {
    margin-top: 108px;
  }
`;

export const StyledEditLabel = styled(EditLabel)<OptionsGroupProps>`
  && {
    margin-left: 8%;

    ${(props) =>
      props.isHidden &&
      css`
        color: ${(props) => props.theme.colors.gray8};
        background: ${(props) => props.theme.colors.gray5};
      `}
  }
`;

export const GivingAdjectiveContentContainer = styled.div<
  AdjectiveContainerProps & OptionsGroupProps
>`
  position: relative;
  width: 100%;

  border-top: 1px solid ${(props) => props.theme.colors.gray12};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray12};

  & > div:first-child {
    margin: 0 auto;
  }

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`;

export const AdjectiveText = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.gray12};
  font-size: ${rem('28px')};
  line-height: ${rem('42px')};
  text-transform: uppercase;

  & > span {
    font-family: ${(props) => props.theme.fontFamilies.vollkorn};
    font-size: ${rem('29px')};
    line-height: 150%;
    font-style: italic;
  }
`;

export const AdjectiveHeader = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.sourceSerif};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: white;
  padding: 64px 24px 14px;
  font-size: ${rem('24px')};
  line-height: ${rem('34px')};

  @media ${(props) => props.theme.screen.md} {
    padding: 0 24px;
    font-size: ${rem('40px')};
    line-height: ${rem('56px')};
  }
`;

export const OptionsGroupContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-left: 8%;
`;

export const EditButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 8px;

    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.gray9};

    & > .anticon + span {
      margin-left: 0px;
    }

    span {
      ${LABEL_2_REG_2}
    }

    span > svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const StyledModal = styled(Modal)`
  overflow: hidden;

  ${minimalisticScrollBar};

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    border-radius: 20px;
    padding: 24px;
    height: 100%;
  }
`;

export const ModalBody = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const AdjectiveMenu = styled(StyledMenu)`
  height: 350px;
`;

export const StyledMenuItem = styled.div<MenuItemProps>`
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px;

  color: ${(props) =>
    props.isSelected ? props.theme.colors.white : props.theme.colors.gray10};
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')};

  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.gray10
      : props.theme.colors.darkEmphasis5};

  &:hover {
    border: 2px solid
      ${(props) =>
        props.isSelected ? props.theme.colors.white : props.theme.colors.black};
    color: ${(props) =>
      props.isSelected ? props.theme.colors.white : props.theme.colors.black};
  }
`;

export const StyledShape = styled(Shape)`
  margin-top: 8px;
  margin-right: 8px;
  width: 33px;
  height: 33px;
  display: inline-block;
`;

export {
  OptionsGroup,
  SeparatorPickerContainer,
  SeparatorColorSection,
  SeparatorPreviewContainer,
};
