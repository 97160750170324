import {
  ArrowLeftOutlined,
  CheckOutlined,
  EyeOutlined,
  GlobalOutlined,
  LinkOutlined,
  LoadingOutlined,
  LockOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Button, FlexCenter } from 'src/client/components';
import { Select } from 'src/client/components/v2';

import { useUpdatePageVisibility } from 'src/client/hooks/useUpdatePageVisibility';
import { getPublicGalleryUrl } from 'src/client/pages/DonorGallery/utils';
import {
  PrivacyOption,
  SectionStatus,
  STATUS_OPTIONS,
} from 'src/client/types/Gallery';
import { UPDATE_ACCESS_LOADING_TIME } from 'src/commons/constants/gallery';
import routes from 'src/commons/constants/routes';
import { Page } from 'src/commons/types';

import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import { pluralize } from 'src/commons/utils/StringUtils';

import RightButtonsContainer from './components/RightButtonsContainer';
import VisibilityChangeSuccessModal from './components/VisibilityChangeSuccessModal';

import * as S from './styles';

type Props = {
  isSavingAll: boolean;
  sectionsStatus: SectionStatus[];
  onCloseAllEdit: () => void;
  onDiscardAllChanges: () => void;
  onSaveAllChanges: () => void;
  page: Page;
};

const PRIVACY_OPTIONS: PrivacyOption[] = [
  {
    value: 'Private',
    label: (
      <S.SelectOptionCurrent>
        <LockOutlined />
        Private
      </S.SelectOptionCurrent>
    ),
    description: 'Only you have access',
    icon: <GlobalOutlined />,
  },
  {
    value: 'Anyone with the link',
    label: (
      <S.SelectOptionCurrent>
        <GlobalOutlined />
        Anyone with the link
      </S.SelectOptionCurrent>
    ),
    description: 'Anyone with the link can view',
    icon: <LockOutlined />,
  },
];

function NavBarEditMode(props: Props) {
  const {
    isSavingAll,
    onCloseAllEdit,
    onDiscardAllChanges,
    onSaveAllChanges,
    page,
    sectionsStatus,
  } = props;
  const { colors } = useTheme();
  const history = useHistory();

  const { updatePageVisibility } = useUpdatePageVisibility(page.id);

  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialPrivacyOption = page.isVisible
    ? PRIVACY_OPTIONS[1]
    : PRIVACY_OPTIONS[0];
  const [privacyOption, setPrivacyOption] = useState(initialPrivacyOption);

  const status: STATUS_OPTIONS = getStatus();
  const { publicLinkDisplay, publicLink } = getPublicGalleryUrl(page.id);
  const sectionsWithChanges = useMemo(
    () =>
      sectionsStatus.filter(
        (sectionStatus) =>
          sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES
      ),
    [sectionsStatus]
  );
  const sectionsWithChangesCount = sectionsWithChanges.length;
  const isOneOfSectionsEditing = sectionsWithChangesCount > 0;

  function getStatus() {
    const isOneOfSectionsHasChanges = sectionsStatus.some(
      (sectionStatus) =>
        sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES
    );
    const isOneOfSectionsEditingWithNoChanges = sectionsStatus.some(
      (sectionStatus) =>
        sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_NO_CHANGES
    );

    if (isOneOfSectionsHasChanges) {
      return STATUS_OPTIONS.EDITING_WITH_CHANGES;
    } else if (isOneOfSectionsEditingWithNoChanges) {
      return STATUS_OPTIONS.EDITING_WITH_NO_CHANGES;
    } else {
      return STATUS_OPTIONS.VIEW_MODE;
    }
  }

  function renderActionButtons() {
    switch (status) {
      case STATUS_OPTIONS.EDITING_WITH_NO_CHANGES:
        return (
          <S.CloseEditButton type="elevated3" onClick={onCloseAllEdit}>
            Close Edits
          </S.CloseEditButton>
        );
      case STATUS_OPTIONS.EDITING_WITH_CHANGES:
        return (
          <RightButtonsContainer>
            <S.UnsavedSectionText type="body2reg2">
              {sectionsWithChangesCount} unsaved{' '}
              {pluralize('section', sectionsWithChangesCount)}
            </S.UnsavedSectionText>
            <S.DiscardButton
              disabled={isSavingAll}
              type="secondary"
              onClick={onDiscardAllChanges}
            >
              Discard all changes
            </S.DiscardButton>
            <Button
              loading={isSavingAll}
              type="primary"
              onClick={onSaveAllChanges}
            >
              <SaveOutlined />
              Save changes
            </Button>
          </RightButtonsContainer>
        );
      default:
        return null;
    }
  }

  function toggleModalVisibility() {
    setIsModalOpen(!isModalOpen);
  }

  function handleOpenPreview() {
    window.open(replaceRouteParams(routes.PUBLIC_GALLERY, { pageId: page.id }));
  }

  function handleGoBack() {
    history.goBack();
  }

  const handleCopyPublicLink = useCallback(() => {
    navigator.clipboard.writeText(publicLink);
    message.info('Copied link!');
  }, [publicLink]);

  const handleSelectPrivacyOption = useCallback(
    async (value: any) => {
      setLoading(true);
      const selectedOption = PRIVACY_OPTIONS.find(
        (opt) => opt.value === (value as string)
      );

      if (selectedOption) {
        setPrivacyOption(selectedOption);

        const isVisible = selectedOption.value !== 'Private';
        await Promise.all([
          new Promise((resolve) =>
            setTimeout(resolve, UPDATE_ACCESS_LOADING_TIME)
          ),
          updatePageVisibility(isVisible),
        ]);

        setLoading(false);
        setIsModalOpen(true);
      }
    },
    [updatePageVisibility]
  );

  const isPrivate = privacyOption.value === 'Private';

  const privacyOptions = useMemo(
    () =>
      PRIVACY_OPTIONS.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          <S.SelectOption>
            <div>
              <span>{option.value}</span>
              <span>{option.description}</span>
            </div>
            <CheckOutlined />
          </S.SelectOption>
        </Select.Option>
      )),
    []
  );

  const copyLinkButton = useMemo(
    () => (
      <Tooltip
        arrowPointAtCenter
        title={isPrivate ? 'Cannot copy link when gallery is Private.' : ''}
      >
        <div>
          <S.CopyLinkButton
            disabled={isPrivate || isLoading || isOneOfSectionsEditing}
            type="secondary"
            onClick={handleCopyPublicLink}
          >
            <LinkOutlined />
            <span>Copy Link</span>
          </S.CopyLinkButton>
        </div>
      </Tooltip>
    ),
    [handleCopyPublicLink, isLoading, isOneOfSectionsEditing, isPrivate]
  );

  const visibilityChangeSuccessModal = isModalOpen && (
    <VisibilityChangeSuccessModal
      handleCopyPublicLink={handleCopyPublicLink}
      open={isModalOpen}
      pageVisibility={page.isVisible}
      publicLinkDisplayText={publicLinkDisplay}
      onCancel={toggleModalVisibility}
      onClose={() => setIsModalOpen(false)}
    />
  );

  return (
    <S.Container>
      {visibilityChangeSuccessModal}

      {isOneOfSectionsEditing ? (
        <FlexCenter>
          <S.BackButton type="variant1" onClick={handleGoBack}>
            <ArrowLeftOutlined />
            <span>Back</span>
          </S.BackButton>
          <S.EditingText type="button-1-2">Editing</S.EditingText>
        </FlexCenter>
      ) : (
        <>
          <S.BackButton type="variant1" onClick={handleGoBack}>
            <ArrowLeftOutlined />
            <span>Back</span>
          </S.BackButton>
          <S.AccessText>Access</S.AccessText>
          {isLoading ? (
            <S.SelectLoadingContainer>
              <LoadingOutlined spin />
            </S.SelectLoadingContainer>
          ) : (
            <>
              <S.PrivacySelectGlobalStyle />
              <S.PrivacySelect
                dropdownStyle={{
                  backgroundColor: colors.white,
                  borderRadius: '8px',
                }}
                optionLabelProp="label"
                value={privacyOption}
                onChange={handleSelectPrivacyOption}
              >
                {privacyOptions}
              </S.PrivacySelect>
            </>
          )}
          {copyLinkButton}
        </>
      )}

      {isOneOfSectionsEditing ? (
        renderActionButtons()
      ) : (
        <S.PreviewButton type="secondary" onClick={handleOpenPreview}>
          <EyeOutlined />
          <span>Preview</span>
        </S.PreviewButton>
      )}
    </S.Container>
  );
}

export default NavBarEditMode;
