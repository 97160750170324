import { FormInstance } from 'antd';
import { useState } from 'react';

import {
  SECTION_NAMES,
  STATUS_OPTIONS,
  SectionStatus,
} from 'src/client/types/Gallery';
import { setAllFieldsTouchedToFalse } from 'src/client/utils/FormUtils';

import { isSectionEditing } from './utils';

const sectionsStatusDefaultValue: SectionStatus[] = [
  {
    name: SECTION_NAMES.INTRO_SECTION,
    status: STATUS_OPTIONS.VIEW_MODE,
  },
  {
    name: SECTION_NAMES.CARDS_SECTION,
    status: STATUS_OPTIONS.VIEW_MODE,
  },
  {
    name: SECTION_NAMES.FOOTER_SECTION,
    status: STATUS_OPTIONS.VIEW_MODE,
  },
  {
    name: SECTION_NAMES.ADJECTIVE_SECTION,
    status: STATUS_OPTIONS.VIEW_MODE,
  },
  {
    name: SECTION_NAMES.THEME,
    status: STATUS_OPTIONS.VIEW_MODE,
  },
];

export function useDonorGallerySectionStatusManager() {
  const [sectionsStatus, setSectionsStatus] = useState<SectionStatus[]>(
    sectionsStatusDefaultValue
  );

  function setSectionStatus(newSectionStatus: SectionStatus) {
    setSectionsStatus((prevSectionsStatus) =>
      prevSectionsStatus.map((sectionStatus) => {
        if (sectionStatus.name === newSectionStatus.name) {
          return newSectionStatus;
        }

        return sectionStatus;
      })
    );
  }

  function setAllSectionStatusToViewMode() {
    sectionsStatus.forEach((sectionStatus) => {
      setSectionStatus({
        name: sectionStatus.name,
        status: STATUS_OPTIONS.VIEW_MODE,
      });
    });
  }

  function exitEditModeToAllSections(formSections: FormInstance<any>[]) {
    setAllSectionStatusToViewMode();
    formSections.forEach((formSection) => {
      setAllFieldsTouchedToFalse(formSection);
    });
  }

  function createSectionStatusSetter(sectionName: SECTION_NAMES) {
    return (newStatus: STATUS_OPTIONS) =>
      setSectionStatus({
        name: sectionName,
        status: newStatus,
      });
  }

  function getSectionStatus(sectionName: SECTION_NAMES) {
    return sectionsStatus.find(
      (sectionStatus) => sectionStatus.name === sectionName
    ) as SectionStatus;
  }

  const isOneOfSectionsEditing = sectionsStatus.some((sectionStatus) =>
    isSectionEditing(sectionStatus)
  );

  return {
    createSectionStatusSetter,
    exitEditModeToAllSections,
    getSectionStatus,
    isOneOfSectionsEditing,
    sectionsStatus,
    setAllSectionStatusToViewMode,
    setSectionStatus,
  };
}
