import { rem } from 'polished';

import styled, { css } from 'styled-components';

import { LABEL_2_REG_2 } from 'src/client/constants/text';

type AccentPickerProps = {
  isLoading: boolean;
};

type AccentColorProps = {
  isSelected: boolean;
  color: string;
  isDisabled: boolean;
};

export const AccentColorPickerContainer = styled.div<AccentPickerProps>`
  position: fixed;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  padding: 10px 10px 10px 14px;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  z-index: 9999;

  border-radius: 80px;
  background: white;
  width: 346px;

  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);

  & > span {
    ${LABEL_2_REG_2}
    line-height: ${rem('14px')};
    white-space: nowrap;
  }

  ${(props) =>
    props.isLoading &&
    css`
      cursor: progress !important;
    `}

  @media ${(props) => props.theme.screen.md} {
    bottom: 32px;
  }
`;

export const AccentColor = styled.div<AccentColorProps>`
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  position: relative;

  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: -5px;
    border: 2px solid
      ${(props) =>
        props.isSelected
          ? props.theme.colors.lightHighEmphasis
          : 'transparent'};
    border-radius: 9999px;
    transition: border-color 0.2s ease;
  }

  &:hover::before {
    border-color: ${(props) =>
      !props.isDisabled &&
      !props.isSelected &&
      props.theme.colors.lightHighEmphasis};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: progress !important;
      pointer-events: none;
      opacity: 0.7;
    `}
`;
