/* eslint-disable no-magic-numbers */
import { rem, rgba } from 'polished';

const colors = {
  // brand colors
  teal1: '#2FECFC',
  teal2: '#0ABCC7',
  royalBlue: '#193B4E',
  darkSecondary: '#E520C6',
  darkPrimary100: '#2FECFC',
  darkPrimary80: rgba(47, 236, 252, 0.8),
  cyan400: '#2FECFC',

  //grays
  gray1: '#FFFFFF',
  gray2: '#71747D',
  gray3: '#F5F5F5',
  gray4: '#F0F0F0',
  gray5: '#BFBFBF',
  gray6: '#DCDCDC',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1f1f1f',
  gray12: '#141414',

  // secondary colors
  blueGray: '#F8FBFD',

  // background
  white: '#FFFFFF',
  white_01: rgba('#FFFFFF', 0.1),
  whiteSmoke: '#F6F4F0',

  // mignight
  midnight4: '#414656',
  midnight7: '#11182C',

  // neutral
  neutral5: '#D9D9D9',
  neutral6: '#F0F0F0',

  // magenta
  magenta200: '#EF7BDD',
  magenta300: '#EA4DD1',
  magenta500: '#E520C6',

  // green
  green500: '#56C22D',
  green300: '#73D64D',

  // red
  red1: '#FEE3E2',
  red6: '#E13C39',

  //text
  darkHighEmphasis: '#11182C',
  darkLowEmphasis: rgba(0, 0, 0, 0.25),
  darkMedEmphasis: rgba(0, 0, 0, 0.6),
  darkEmphasis1: '#11182C',
  darkEmphasis2: rgba(17, 24, 44, 0.6),
  darkEmphasis3: rgba(17, 24, 44, 0.4),
  darkEmphasis4: rgba(17, 24, 44, 0.2),
  darkEmphasis5: rgba(17, 24, 44, 0.06),
  lightEmphasis2: rgba(255, 255, 255, 0.7),
  lightEmphasis3: rgba(255, 255, 255, 0.4),
  lightMedEmphasis: rgba(256, 255, 255, 0.6),
  lightHighEmphasis: '#11182C',
  lightLowEmphasis: rgba(256, 255, 255, 0.25),
  title85: rgba(0, 0, 0, 0.85),

  //border
  border1: rgba(0, 0, 0, 0.06),
  border2: rgba('#FFFFFF', 0.2),
  border3: rgba(0, 0, 0, 0.3),

  //surface
  darkSurface1: '#11182C',
  darkSurface2: '#1D2437',
  darkSurface3: '#262D3F',
  darkSurface3_08: rgba(38, 45, 63, 0.08),
  lightSurface80: rgba(255, 255, 255, 0.8),
  lightSurface20: rgba(255, 255, 255, 0.2),
  surfaceDisabled: '#DBDBDB',

  // extras
  black: '#000000',
  error: '#F36969',
  danger500: '#FB4E54',
  darkOpacity50: rgba(17, 24, 44, 0.5),
  activeGradient:
    'linear-gradient(0deg, rgba(243, 109, 109, 0.72) 0%, rgba(243, 109, 109, 0) 100%)',
  inactiveGradient:
    'linear-gradient(0deg, #0abcc7 0%, rgba(10, 188, 199, 0) 100%)',
  cornSilk: '#FFFBE6',
  yellow: '#FFE58F',
  ExclamationCircleFilledColor: '#FAAD14',
  success: '#56C22D',
  warning: '#F8AC2F',
  accent1: '#8EE82D',
  givingSideMidnight: '#11182C',
  randomizeYellow: rgba(255, 198, 69, 1),
  seeAllPink: rgba(245, 216, 250, 1),
};

export const fonts = {
  avenirNext: 'Avenir Next',
};

const fontFamilies = {
  playfair: "'Playfair Display', serif",
  regular: "'Spartan', sans-serif",
  leagueSpartan: "'League Spartan', sans-serif",
  sfPro: "'SF Pro', sans-serif",
  sourceSerif: "'Source Serif 4', serif",
  vollkorn: "'Vollkorn', serif",
};

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const fontSizes = {
  h1: rem('72px'),
  h2: rem('64px'),
  h3: rem('48px'),
  h4: rem('36px'),
  h5: rem('30px'),
  subtitle1: rem('24px'),
  subtitle2: rem('18px'),
  body: rem('18px'),
  body2: rem('14px'),
  buttonSize: rem('14px'),
  overline: rem('14px'),
  caption1: rem('16px'),
  caption2: rem('12px'),
  captionReg2: rem('12px'),
};

const shadows = {
  elevation1: '0px 2px 4px rgba(147, 156, 174, 0.24)',
  elevation2: '0px 4px 8px rgba(147, 156, 174, 0.24)',
  elevation3: '0px 8px 16px rgba(147, 156, 174, 0.24)',
  elevation4: '0 0 0 2px rgb(24 144 255 / 20%)',
  shadow2: '0px 2px 14px rgba(0, 0, 0, 0.03)',
  shadow3: '0px 7px 29px 0px rgba(100, 100, 111, 0.2) ',
};

const size = {
  mobileS: '320px',
  mobileM: '376px',
  mobileL: '426px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

const extras = {
  maxWidth: '1440px',
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const screen = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
};

const input = {
  large: {
    height: '40px',
    borderRadius: '8px',
  },
  middle: {
    height: '32px',
    borderRadius: '4px',
  },
};

const defaultTheme = {
  colors,
  device,
  extras,
  fontFamilies,
  fontSizes,
  fontWeights,
  input,
  screen,
  shadows,
  size,
};

export default defaultTheme;
