import { RECIPIENT_TYPE } from 'src/commons/types';

export const HIGHTLIGHTED_PAGE_RECIPIENT_LIMIT = 3;

export const RECIPIENT_TYPE_DISPLAY: Record<RECIPIENT_TYPE, string> = {
  [RECIPIENT_TYPE.CROWDFUNDING]: 'Crowdfunding',
  [RECIPIENT_TYPE.FOR_PROFIT]: 'For Profit',
  [RECIPIENT_TYPE.FOR_PROFIT_B_CORP]: 'For Profit B Corp',
  [RECIPIENT_TYPE.INDIVIDUAL]: 'Individual',
  [RECIPIENT_TYPE.LABOR_UNION]: 'Labor Union',
  [RECIPIENT_TYPE.NONPROFIT]: 'Nonprofit',
  [RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED]: 'Nonprofit Fiscally Sponsored',
  [RECIPIENT_TYPE.OTHER]: 'Other',
  [RECIPIENT_TYPE.POLITICAL_C4]: 'Political C4',
  [RECIPIENT_TYPE.POLITICAL_CANDIDATE]: 'Political Candidate',
  [RECIPIENT_TYPE.POLITICAL_PAC]: 'Political PAC',
};

export const RECIPIENT_TYPE_ORDER = [
  RECIPIENT_TYPE.CROWDFUNDING,
  RECIPIENT_TYPE.NONPROFIT,
  RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED,
  RECIPIENT_TYPE.POLITICAL_C4,
  RECIPIENT_TYPE.POLITICAL_CANDIDATE,
  RECIPIENT_TYPE.POLITICAL_PAC,
  RECIPIENT_TYPE.LABOR_UNION,
  RECIPIENT_TYPE.FOR_PROFIT,
  RECIPIENT_TYPE.FOR_PROFIT_B_CORP,
  RECIPIENT_TYPE.OTHER,
  RECIPIENT_TYPE.INDIVIDUAL,
];

export const defaultVisibleTypes = Object.values(RECIPIENT_TYPE).filter(
  (type) => type !== RECIPIENT_TYPE.INDIVIDUAL
);
