import { rem } from 'polished';

import styled, { css } from 'styled-components';

import { GalleryTextArea } from 'src/client/components';

import { EditLabel, OptionsGroup } from '../../styles';

type OptionsGroupProps = {
  isHidden: boolean;
};

export const Container = styled.div`
  display: flex;
  margin-bottom: 48px;

  @media ${(props) => props.theme.screen.md} {
    margin-bottom: 80px;
  }

  @media ${(props) => props.theme.screen.sm} {
    justify-content: center;
  }
`;

export const StyledEditLabel = styled(EditLabel)<OptionsGroupProps>`
  && {
    ${(props) =>
      props.isHidden &&
      css`
        color: ${(props) => props.theme.colors.gray8};
        background: ${(props) => props.theme.colors.gray5};
      `}
  }
`;

export const GalleryDescriptionInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${(props) => props.theme.screen.sm} {
    width: 344px;
  }
`;

const GalleryDescriptionText = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const GalleryDescriptionTextArea = styled(GalleryTextArea)`
  padding: 7px 4px;
  overflow: hidden;
  resize: none;
  text-align: center;

  ${GalleryDescriptionText}

  ${(props) =>
    !props.isEditing &&
    css`
      opacity: 0.2;
    `}

  border: ${(props) =>
    props.isEditing
      ? `1px solid ${props.theme.colors.border3}`
      : `1px solid ${props.theme.colors.gray5}`};
  border-radius: 4px;

  &:hover,
  &:focus {
    border: ${(props) =>
      props.isEditing
        ? `1px solid ${props.theme.colors.border3}`
        : `1px solid ${props.theme.colors.gray5}`};
    box-shadow: none;
  }

  @media ${(props) => props.theme.screen.sm} {
    min-width: 340px;
  }
`;

export const DescriptionDisplay = styled.span`
  min-width: 340px;
  width: 100%;
  text-align: center;
  margin-top: 24px;

  ${GalleryDescriptionText}

  @media ${(props) => props.theme.screen.sm} {
    width: 344px;
  }
`;

export { OptionsGroup };
