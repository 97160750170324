import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex, Button, Text } from 'src/client/components';
import Marquee from 'src/client/components/Marquee';
import { analytics } from 'src/client/libs/segment';
import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import {
  GALLERY_SHAPES,
  GalleryColor,
  MARQUEE_SPEED,
  SEPARATOR_SHAPES_COUNT,
} from 'src/commons/constants/gallery';
import { GivingAdjective } from 'src/commons/constants/givingAdjectives';

import routes from 'src/commons/constants/routes';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';

import { Donor } from 'src/commons/types';

import * as S from './styles';

const SEPARATOR_SHAPES = GALLERY_SHAPES.slice(
  1,
  GALLERY_SHAPES.length - SEPARATOR_SHAPES_COUNT
);

const { VIEW_MODE } = STATUS_OPTIONS;

type Props = {
  adjectiveSectionForm: FormInstance<any>;
  donor: Donor;
  handleFormChange: () => void;
  isUpdating: boolean;
  isViewingPublicly: boolean;
  sectionStatus: SectionStatus;
  themeColor: GalleryColor;
  setSectionStatus: (newStatus: STATUS_OPTIONS) => void;
};

function AdjectiveSection(props: Props) {
  const {
    adjectiveSectionForm,
    donor,
    handleFormChange,
    isUpdating,
    isViewingPublicly,
    sectionStatus,
    setSectionStatus,
    themeColor,
  } = props;

  const { colors } = useTheme();
  const history = useHistory();

  const currentRoute = history.location.pathname;
  const isPreview = currentRoute.includes(
    routes.PUBLIC_GALLERY.replace(':pageId', '')
  );

  const galleryAdjective =
    adjectiveSectionForm.getFieldValue('galleryAdjective');
  const isAdjectiveSectionVisible = adjectiveSectionForm.getFieldValue(
    'isAdjectiveSectionVisible'
  );
  const currentAdjective = getAdjective(galleryAdjective as string);

  const [isHidden, setIsHidden] = useState<boolean>(!isAdjectiveSectionVisible);
  const [isModalOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    currentAdjective as GivingAdjective
  );

  useEffect(() => {
    if (sectionStatus.status === STATUS_OPTIONS.VIEW_MODE) {
      // reset state
      setSelectedItem(currentAdjective as GivingAdjective);
      setIsHidden(!isAdjectiveSectionVisible);
    }
  }, [currentAdjective, isAdjectiveSectionVisible, sectionStatus.status]);

  const isDoneDisabled = useMemo(
    () => selectedItem === currentAdjective,
    [selectedItem, currentAdjective]
  );

  const handleSelect = useCallback((newAdjective: GivingAdjective) => {
    setSelectedItem(newAdjective);
  }, []);

  function getAdjective(value: string) {
    const isAlphabetic = /^[a-zA-Z]+$/;

    return isAlphabetic.test(value) ? value : '';
  }

  function startEditing() {
    setOpen(true);

    analytics.track(
      SEGMENT_EVENTS.USER_CLICKED_AN_EDIT_GALLERY_SECTION_BUTTON,
      {
        section: 'adjective',
        ownerName: donor?.name,
        donorEmail: donor?.email,
      }
    );
  }

  function cancelEditing() {
    setOpen(false);
    setSelectedItem(currentAdjective as GivingAdjective);
  }

  function handleDone() {
    if (selectedItem === getAdjective(donor?.descriptionCurrent as string)) {
      setSectionStatus(VIEW_MODE);
      adjectiveSectionForm.resetFields();
    } else {
      adjectiveSectionForm.setFieldsValue({
        galleryAdjective: selectedItem,
      });
      handleFormChange();
    }

    setOpen(false);
  }

  function handleSetAdjectiveSectionVisibility(value: boolean) {
    adjectiveSectionForm.setFieldsValue({
      isAdjectiveSectionVisible: !value,
    });
    setIsHidden(value);

    handleFormChange();
  }

  const menuItemDisplay = useMemo(
    () =>
      Object.values(GivingAdjective).map((item) => (
        <S.StyledMenuItem
          data-cy={`adjective-item ${item.toLowerCase()}`}
          data-cy-selected={selectedItem === item ? 'true' : 'false'}
          isSelected={selectedItem === item}
          key={item}
          onClick={(_event) => handleSelect(item)}
        >
          {item}
        </S.StyledMenuItem>
      )),
    [selectedItem, handleSelect]
  );

  const editButton = !isViewingPublicly && (
    <S.EditButton
      data-cy="edit-status-btn"
      disabled={isUpdating}
      type="noStyle"
      onClick={startEditing}
    >
      <EditOutlined />
      Edit
    </S.EditButton>
  );

  if (isViewingPublicly && !currentAdjective) {
    return null;
  }

  if (isViewingPublicly && isHidden) {
    return null;
  }

  return (
    <>
      <S.GivingAdjectiveSection>
        {!isViewingPublicly && (
          <S.StyledEditLabel isHidden={false}>
            Your Giving Side in one word
          </S.StyledEditLabel>
        )}
        <S.GivingAdjectiveContentContainer
          isHidden={isHidden}
          isPreview={isPreview}
        >
          <Marquee
            galleryShapeColor={themeColor}
            scrollSpeed={isHidden ? 0 : MARQUEE_SPEED}
            separatorShapes={SEPARATOR_SHAPES}
          >
            <S.AdjectiveText>
              My Giving Side is <span>{currentAdjective}</span>
            </S.AdjectiveText>
          </Marquee>
        </S.GivingAdjectiveContentContainer>
        {!isViewingPublicly && (
          <Form.Item noStyle name="isAdjectiveSectionVisible">
            <S.OptionsGroupContainer>
              {editButton}
              <S.OptionsGroup isHidden={isHidden}>
                <Button
                  type="noStyle"
                  onClick={() => handleSetAdjectiveSectionVisibility(false)}
                >
                  <EyeOutlined height={20} />
                  <span>Show</span>
                </Button>
                <Button
                  type="noStyle"
                  onClick={() => handleSetAdjectiveSectionVisibility(true)}
                >
                  <EyeInvisibleOutlined />
                  <span>Hide</span>
                </Button>
              </S.OptionsGroup>
            </S.OptionsGroupContainer>
          </Form.Item>
        )}
      </S.GivingAdjectiveSection>

      <S.StyledModal
        centered
        data-cy="adjective-modal"
        footer={false}
        open={isModalOpen}
        zIndex={10000}
        onCancel={cancelEditing}
      >
        <Text type="h4med2">Select a word that describes your Giving Side</Text>
        <Text color={colors.darkEmphasis2} type="body2reg2">
          Personalize your gallery by showcasing a word that describes your
          giving.
        </Text>
        <S.ModalBody>
          <Text color={colors.darkHighEmphasis} type="label1med2">
            My Giving Side is...
          </Text>
          <Form.Item noStyle name="galleryAdjective">
            <S.AdjectiveMenu>{menuItemDisplay}</S.AdjectiveMenu>
          </Form.Item>
        </S.ModalBody>
        <Flex gap="8px" justifyContent="flex-end">
          <Button
            data-cy="cancel-save-adjective-btn"
            type="secondary"
            onClick={cancelEditing}
          >
            Cancel
          </Button>
          <Button disabled={isDoneDisabled} type="primary" onClick={handleDone}>
            Done
          </Button>
        </Flex>
      </S.StyledModal>
    </>
  );
}

export default AdjectiveSection;
