import { EditOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import React, { useState } from 'react';

import { handleGetValueFromUploadEvent } from 'src/client/utils/FormUtils';
import { GalleryColor } from 'src/commons/constants/gallery';
import { Page } from 'src/commons/types';

import GalleryShapeModal from './components/GalleryShapeModal';
import * as S from './styles';

type Props = {
  introSectionForm: FormInstance<any>;
  isViewingPublicly: boolean;
  themeColor: GalleryColor;
  rotation?: number;
  onChange?: () => void;
};

function GalleryShapeInput(props: Props) {
  const {
    introSectionForm,
    isViewingPublicly,
    rotation,
    themeColor,
    onChange,
  } = props;

  const galleryShape = useWatch('galleryShape', introSectionForm);

  const galleryCustomImage =
    introSectionForm.getFieldValue('galleryCustomImage');

  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModalVisibility() {
    setIsModalOpen(!isModalOpen);
  }

  function handleSave(newGalleryShape: Page['galleryShape']) {
    introSectionForm.setFields([
      {
        name: 'galleryShape',
        value: newGalleryShape,
        touched: true,
      },
    ]);

    if (onChange) {
      onChange();
    }

    toggleModalVisibility();
  }

  function getGalleryCustomImageUrlFormValue() {
    if (galleryCustomImage?.[0]?.url) {
      return galleryCustomImage[0].url;
    } else if (galleryCustomImage?.[0]?.originFileObj) {
      return URL.createObjectURL(galleryCustomImage[0].originFileObj);
    }

    return undefined;
  }

  const customizeButton = !isViewingPublicly && (
    <S.CustomizeButton onClick={toggleModalVisibility}>
      <EditOutlined height={20} />
      Edit
    </S.CustomizeButton>
  );

  const galleryCustomImageUrlFormValue = getGalleryCustomImageUrlFormValue();

  const galleryShapeOutput = (
    <S.DisplayContainer>
      <Form.Item
        noStyle
        getValueFromEvent={handleGetValueFromUploadEvent}
        name="galleryCustomImage"
      >
        <S.CustomImage
          alt="Uploaded"
          galleryCustomImage={galleryCustomImage}
          src={
            Array.isArray(galleryCustomImage)
              ? galleryCustomImageUrlFormValue
              : galleryCustomImage
          }
        />
      </Form.Item>

      <Form.Item noStyle name="galleryShape">
        <div
          style={{
            transform: `rotate(${rotation}deg)`,
            transition: 'transform 200ms ease-out',
          }}
        >
          <S.StyledShape
            galleryCustomImage={galleryCustomImage}
            shape={{
              name: galleryShape?.name,
              color: themeColor,
            }}
          />
        </div>
      </Form.Item>
      {customizeButton}
    </S.DisplayContainer>
  );

  const galleryShapeModal = isModalOpen && (
    <GalleryShapeModal
      introSectionForm={introSectionForm}
      open={isModalOpen}
      themeColor={themeColor}
      onCancel={toggleModalVisibility}
      onCloseModal={() => setIsModalOpen(false)}
      onSave={handleSave}
    />
  );

  return (
    <S.Container>
      {galleryShapeOutput}
      {galleryShapeModal}
    </S.Container>
  );
}

export default GalleryShapeInput;
