import styled from 'styled-components';

import { Text } from 'src/client/components';

type FooterSectionProps = {
  color: string;
};

export const FooterSection = styled.section<FooterSectionProps>`
  width: 100%;
  height: 75px;
  background: ${(props) => props.color};

  display: flex;
  align-items: center;
  padding: 24px 28px;
`;

export const FooterText = styled(Text)`
  font-family: 'SF Pro';
  font-size: 14px;
  font-weight: 510;
  line-height: 120%; /* 16.8px */
  color: white;
`;
