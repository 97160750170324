import { CheckCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance, message } from 'antd';
import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Title } from 'src/client/components';
import {
  useUpdateDonorMutation,
  useUpdatePageMutation,
  useUploadPageCustomImageMutation,
} from 'src/client/hooks/mutations';
import { useUpdatePageRecipientsMutation } from 'src/client/hooks/mutations/pageRecipientMutation';
import {
  GET_GALLERY_CUSTOM_IMAGES_KEY,
  GET_PAGE_RECIPIENTS_KEY,
  updateGetDonorByIdQuery,
  updateGetPageQueryData,
  useGetDonorById,
  useGetPage,
  useGetPageRecipients,
} from 'src/client/hooks/queries';
import { useScrollRotation } from 'src/client/hooks/useScrollRotation';
import { analytics } from 'src/client/libs/segment';
import { SECTION_NAMES, STATUS_OPTIONS } from 'src/client/types/Gallery';

import {
  createUploadFormItemValue,
  getTouchedFieldsValue,
} from 'src/client/utils/FormUtils';
import {
  DEFAULT_GALLERY_DESCRIPTION,
  DEFAULT_GALLERY_INTRODUCTION_AUTHOR,
  DEFAULT_GALLERY_INTRODUCTION_QUOTE,
  DEFAULT_GALLERY_SHAPE,
  DEFAULT_GALLERY_TITLE,
  GalleryColor,
} from 'src/commons/constants/gallery';

import routes from 'src/commons/constants/routes';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Page, PageRecipient, RECIPIENT_TYPE } from 'src/commons/types';
import { asyncForEach } from 'src/commons/utils/ArrayUtils';
import { convertEmptyStringsToNulls } from 'src/commons/utils/ObjectUtil';
import {
  getFileNameFromPath,
  getLastLetter,
} from 'src/commons/utils/StringUtils';

import MobileDismissableAlert from '../DonorGallery/components/IntroSection/components/MobileDismissableAlert';

import { useDonorGallerySectionStatusManager } from '../DonorGallery/useDonorGallerySectionStatusManager';
import LoadingPage from '../LoadingPage';
import PageNotFound from '../PageNotFound';

import AdjectiveSection from './components/AdjectiveSection';
import CardsSection from './components/CardsSection';
import FooterSection from './components/FooterSection';
import GalleryDescriptionInput from './components/GalleryDescriptionInput';
import GalleryQuoteAuthorInput from './components/GalleryQuoteAuthorInput';
import GalleryShapeInput from './components/GalleryShapeInput';
import GalleryTitleInput from './components/GalleryTitleInput';
import NavBarEditMode from './components/NavbarEditMode';
import PrivacyStatusInfo from './components/PrivacyStatusInfo';
import ThemeColorPicker from './components/ThemeColorPicker';
import * as S from './styles';

type Props = {
  isViewingPublicly: boolean;
};

type UrlParams = {
  pageId: string;
};

const { useForm } = Form;

function DonorGalleryV2(props: Props) {
  const { isViewingPublicly } = props;

  const match = useRouteMatch<UrlParams>();
  const { pageId } = match.params;
  const history = useHistory();

  const [introSectionFormV2] = useForm();
  const [adjectiveSectionForm] = useForm();
  const [themeColorForm] = useForm();

  const currentThemeColor = themeColorForm.getFieldValue('themeColor');

  const queryClient = useQueryClient();
  const {
    data: page,
    isLoading: isGetPageLoading,
    isFetching: isGetPageFetching,
  } = useGetPage(pageId);
  const { data: donor, isLoading: isDonorLoading } = useGetDonorById(
    page?.ownerId
  );

  const [pageRecipientsEditData, setPageRecipientsEditData] = useState<
    PageRecipient[]
  >([]);
  const [visibleTypes, setVisibleTypes] = useState<Set<RECIPIENT_TYPE>>(
    new Set(Object.values(RECIPIENT_TYPE))
  );
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const titleInputRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const rect = footerRef.current.getBoundingClientRect();
        const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (isInView) {
          footerRef.current.style.height = '100vh';
        } else {
          footerRef.current.style.height = '200vh'; // Original height
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    introSectionFormV2.resetFields();
  }, [introSectionFormV2, isGetPageFetching]);

  const queryDataOfGetPageRecipients = {
    pageId: page?.id as string,
  };

  const {
    data: pageRecipients,
    isLoading: isPageRecipientsLoading,
    isIdle: isPageRecipientIdle,
  } = useGetPageRecipients(queryDataOfGetPageRecipients, {
    enabled: !!page?.id,
  });

  const { mutateAsync: updatePage, isLoading: isUpdatingPage } =
    useUpdatePageMutation({
      onSuccess: (updatePayload) => {
        updateGetPageQueryData({
          queryClient,
          data: updatePayload,
        });
      },
    });

  const {
    mutateAsync: uploadPageCustomImage,
    isLoading: isUploadingPageCustomImage,
  } = useUploadPageCustomImageMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_GALLERY_CUSTOM_IMAGES_KEY, {
        refetchInactive: true,
      });
    },
  });

  const { mutateAsync: updateDonor, isLoading: isUpdatingDonor } =
    useUpdateDonorMutation({
      onSuccess: (data) => {
        updateGetDonorByIdQuery({
          data,
          donorId: data.id,
          queryClient,
        });
      },
    });

  const {
    mutateAsync: updatePageRecipient,
    isLoading: isUpdatingPageRecipients,
  } = useUpdatePageRecipientsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PAGE_RECIPIENTS_KEY]);
    },
  });

  const isSavingAll =
    isUpdatingDonor ||
    isUpdatingPage ||
    isUploadingPageCustomImage ||
    isUpdatingPageRecipients;

  const {
    createSectionStatusSetter,
    exitEditModeToAllSections,
    getSectionStatus,
    sectionsStatus,
    setAllSectionStatusToViewMode,
    setSectionStatus,
  } = useDonorGallerySectionStatusManager();

  const rotation = useScrollRotation({ container });

  function getInitialValues(page: Page) {
    return {
      quote: page.quote ?? DEFAULT_GALLERY_INTRODUCTION_QUOTE,
      author:
        page.author !== undefined
          ? page.author
          : DEFAULT_GALLERY_INTRODUCTION_AUTHOR,
      ownerName: page.ownerName,
      introductionDescription:
        page.introductionDescription ?? DEFAULT_GALLERY_DESCRIPTION,
      isIntroductionTitleEditable: page.isIntroductionTitleEditable ?? true,
      isIntroductionDescriptionVisible:
        page.isIntroductionDescriptionVisible ?? true,
      galleryCustomImage:
        page.galleryCustomImageFileUrl && page.galleryCustomImageFilePath
          ? [
              createUploadFormItemValue({
                url: page.galleryCustomImageFileUrl,
                fileName: getFileNameFromPath(
                  page.galleryCustomImageFilePath as string
                ),
              }),
            ]
          : undefined,
      galleryShape: {
        color: page.galleryShape?.color ?? DEFAULT_GALLERY_SHAPE?.color,
        name: page.galleryShape?.name ?? DEFAULT_GALLERY_SHAPE?.name,
      },
      isQuoteAuthorVisible: page.isQuoteAuthorVisible ?? true,
    };
  }

  function showSuccessMessage() {
    message.success({
      icon: <CheckCircleOutlined />,
      className: 'update-page-success-message',
      content: 'Changes saved',
    });
  }

  async function saveAllChanges() {
    try {
      const formsToSave = [
        { name: 'introSectionForm', form: introSectionFormV2 },
        { name: 'themeColorForm', form: themeColorForm },
      ];

      const [, , cardsFilteringUpdatePayload, imageUpdate, formsUpdatePayload] =
        await Promise.all([
          saveCardsSectionChanges(),
          saveAdjectiveSection(),
          processCardsFiltering(),
          processCustomImage(),
          processForms(formsToSave),
        ]);

      const { needsQueryInvalidation, ...imageUpdatePayload } = imageUpdate;

      const updatePayload = {
        id: (page as Page).id,
        ...cardsFilteringUpdatePayload,
        ...imageUpdatePayload,
        ...formsUpdatePayload,
      };

      if (Object.keys(updatePayload).length > 1) {
        await updatePage(updatePayload);
        updateGetPageQueryData({
          queryClient,
          data: updatePayload,
        });

        if (needsQueryInvalidation) {
          queryClient.invalidateQueries(GET_GALLERY_CUSTOM_IMAGES_KEY, {
            refetchInactive: true,
          });
        }
      }

      exitEditModeToAllSections(formsToSave.map((item) => item.form));
      showSuccessMessage();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async function processCustomImage(): Promise<{
    galleryCustomImageFilePath?: null;
    needsQueryInvalidation?: boolean;
  }> {
    const touchedFields = getTouchedFieldsValue(introSectionFormV2);

    if (!('galleryCustomImage' in touchedFields)) {
      return {};
    }

    const file = touchedFields.galleryCustomImage?.[0];

    if (file) {
      await uploadPageCustomImage({
        file: file.originFileObj as File,
        pageId: page?.id as string,
      });

      return {};
    }

    return {
      galleryCustomImageFilePath: null,
      needsQueryInvalidation: true,
    };
  }

  async function processForms(
    formsToSave: { name: string; form: FormInstance<any> }[]
  ): Promise<Partial<Page>> {
    let formUpdates: Partial<Page> = {};
    const ignoredFormItems = ['galleryCustomImage'];

    await asyncForEach(formsToSave, async (item) => {
      const { form: formSection, name } = item;
      await formSection.validateFields();

      if (name === 'themeColorForm') {
        const touchedFields = getTouchedFieldsValue(formSection);

        if (Object.keys(touchedFields).includes('themeColor')) {
          formUpdates.themeColor = touchedFields.themeColor;
        }
      } else if (name === 'introSectionForm') {
        const touchedFields = getTouchedFieldsValue(formSection);
        const touchedFieldsWithNulls =
          convertEmptyStringsToNulls(touchedFields);

        // Temporary fix for handling quote and author fields when chosen from curated quotes
        const isQuoteChanged =
          page?.quote !== formSection.getFieldValue('quote');
        const isAuthorChanged =
          page?.author !== formSection.getFieldValue('author');
        let quoteAuthorPayload = {};

        // This code checks if the 'quote' or 'author' fields have been modified.
        // If the quote has been changed and it's not in the touchedFieldsWithNulls, or if the author has been changed,
        // the quoteAuthorPayload object is updated with the new quote or author value.
        quoteAuthorPayload = {
          ...(isQuoteChanged && {
            quote: formSection.getFieldValue('quote') || null,
          }),
          ...(isAuthorChanged && {
            author: formSection.getFieldValue('author') || null,
          }),
        };

        formUpdates = {
          ...formUpdates,
          ...omit(touchedFieldsWithNulls, ignoredFormItems),
          ...quoteAuthorPayload,
        };
      }
    });

    return formUpdates;
  }

  async function saveAdjectiveSection() {
    const payload = adjectiveSectionForm.getFieldsValue();
    const { galleryAdjective, isAdjectiveSectionVisible } = payload;

    if (galleryAdjective || isAdjectiveSectionVisible) {
      await updateDonor({
        id: donor?.id as string,

        ...(galleryAdjective && {
          descriptionCurrent: galleryAdjective,
          descriptionPrevious: donor?.descriptionCurrent,
        }),
        ...(isAdjectiveSectionVisible !== undefined && {
          isAdjectiveSectionVisible,
        }),
      });

      analytics.track(SEGMENT_EVENTS.USER_SAVED_A_GALLERY_SECTION, {
        section: 'adjection',
        newAdjective: galleryAdjective,
        previousAdjective: donor?.descriptionCurrent,
      });
    }
  }

  async function saveCardsSectionChanges() {
    if (pageRecipientsEditData.length > 0) {
      const sanitizedPageRecipientsEditData = pageRecipientsEditData.map(
        (pageRecipient) => {
          const {
            imageId,
            imageLegacyId,
            recipientWebsite,
            ...pageRecipientData
          } = pageRecipient as any;

          return pageRecipientData;
        }
      );
      await updatePageRecipient(sanitizedPageRecipientsEditData);
      setPageRecipientsEditData([]);
      setSectionStatus({
        name: SECTION_NAMES.CARDS_SECTION,
        status: STATUS_OPTIONS.VIEW_MODE,
      });
    }
  }

  function processCardsFiltering(): Pick<Page, 'visibleRecipientTypes'> | {} {
    const currentTypes = Array.from(visibleTypes);
    const originalTypes = page?.visibleRecipientTypes || [];

    const hasChanges =
      currentTypes.length !== originalTypes.length ||
      currentTypes.some((type) => !originalTypes.includes(type));

    if (!hasChanges) {
      return {};
    }

    return {
      visibleRecipientTypes: currentTypes,
    };
  }

  function handleFormChange(section: SECTION_NAMES, form: FormInstance<any>) {
    const isFieldsTouched = form.isFieldsTouched();

    const setSectionStatus = createSectionStatusSetter(section);

    if (isFieldsTouched) {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_CHANGES);
    } else {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);
    }
  }

  function handleIntroFormV2Change() {
    handleFormChange(SECTION_NAMES.INTRO_SECTION, introSectionFormV2);
  }

  function handleAdjectiveSectionFormChange() {
    handleFormChange(SECTION_NAMES.ADJECTIVE_SECTION, adjectiveSectionForm);
  }

  async function handleSelectThemeColor(color: GalleryColor) {
    themeColorForm.setFieldsValue({ themeColor: color });
    handleFormChange(SECTION_NAMES.THEME, themeColorForm);
  }

  function discardAllChanges() {
    introSectionFormV2.resetFields();
    adjectiveSectionForm.resetFields();
    themeColorForm.resetFields();

    if (pageRecipients) {
      setPageRecipientsEditData(pageRecipients);
    }

    setVisibleTypes(new Set(page?.visibleRecipientTypes));

    setAllSectionStatusToViewMode();
  }

  function makeGalleryTitle(ownerName: string) {
    const ownerNameLastLetter = getLastLetter(ownerName);

    if (ownerNameLastLetter === 's') {
      return `${ownerName}‘ Giving Side`;
    } else {
      return `${ownerName}‘s Giving Side`;
    }
  }

  function redirectToRoot() {
    history.push(routes.ROOT);
  }

  if (isGetPageLoading || isDonorLoading) {
    return <LoadingPage />;
  }

  if (!page || !donor) {
    return <PageNotFound />;
  }

  const navBar = !isViewingPublicly && (
    <NavBarEditMode
      isSavingAll={isSavingAll}
      page={page}
      sectionsStatus={sectionsStatus}
      onCloseAllEdit={setAllSectionStatusToViewMode}
      onDiscardAllChanges={discardAllChanges}
      onSaveAllChanges={saveAllChanges}
    />
  );

  const title =
    !page.isIntroductionTitleEditable || page.ownerName === null
      ? DEFAULT_GALLERY_TITLE
      : makeGalleryTitle(page.ownerName);
  const showPrivacyStatus = isViewingPublicly && !page.isVisible;

  const stickyHeader = isViewingPublicly && (
    <StickyHeader
      galleryTitleRef={titleInputRef}
      privacyStatusContent={
        <PrivacyStatusInfo isViewingPublicly={isViewingPublicly} page={page} />
      }
      showPrivacyStatus={showPrivacyStatus}
      title={title}
    />
  );

  const stickyBottomBadge = isViewingPublicly && (
    <S.PoweredByContainer onClick={redirectToRoot}>
      Powered by
      <S.Logo src="/logo.png" />
    </S.PoweredByContainer>
  );

  const accentColorPicker = (
    <Form
      form={themeColorForm}
      initialValues={{
        themeColor: page?.themeColor ?? DEFAULT_GALLERY_SHAPE?.color,
      }}
    >
      {!isViewingPublicly && (
        <ThemeColorPicker
          handleSelectThemeColor={handleSelectThemeColor}
          isLoading={isSavingAll}
          themeColorForm={themeColorForm}
        />
      )}
    </Form>
  );

  return (
    <>
      <Title title="Gallery - Giving Side" />

      <S.Container ref={setContainer}>
        <S.DonorGalleryGlobalStyle />
        {navBar}
        <MobileDismissableAlert />

        <S.IntroSectionContainer>
          {stickyHeader}

          <Form
            form={introSectionFormV2}
            initialValues={getInitialValues(page)}
            onFieldsChange={handleIntroFormV2Change}
          >
            <div ref={titleInputRef}>
              <GalleryTitleInput
                galleryVisibility={page.isVisible}
                handleFormChange={handleIntroFormV2Change}
                introSectionForm={introSectionFormV2}
                isViewingPublicly={isViewingPublicly}
                sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
              />
            </div>
            <GalleryDescriptionInput
              handleFormChange={handleIntroFormV2Change}
              introSectionForm={introSectionFormV2}
              isViewingPublicly={isViewingPublicly}
              sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
            />
            <GalleryShapeInput
              introSectionForm={introSectionFormV2}
              isViewingPublicly={isViewingPublicly}
              rotation={rotation}
              themeColor={
                currentThemeColor ??
                (DEFAULT_GALLERY_SHAPE?.color as GalleryColor)
              }
              onChange={handleIntroFormV2Change}
            />
            <GalleryQuoteAuthorInput
              adjectiveSectionForm={adjectiveSectionForm}
              handleFormChange={handleIntroFormV2Change}
              introSectionForm={introSectionFormV2}
              isViewingPublicly={isViewingPublicly}
              page={page}
              sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
            />
          </Form>
        </S.IntroSectionContainer>

        <Form
          form={adjectiveSectionForm}
          initialValues={{
            isAdjectiveSectionVisible: donor.isAdjectiveSectionVisible ?? true,
            galleryAdjective: donor?.descriptionCurrent as string,
          }}
        >
          <AdjectiveSection
            adjectiveSectionForm={adjectiveSectionForm}
            donor={donor}
            handleFormChange={handleAdjectiveSectionFormChange}
            isUpdating={isSavingAll}
            isViewingPublicly={isViewingPublicly}
            sectionStatus={getSectionStatus(SECTION_NAMES.ADJECTIVE_SECTION)}
            setSectionStatus={createSectionStatusSetter(
              SECTION_NAMES.ADJECTIVE_SECTION
            )}
            themeColor={
              currentThemeColor ??
              (DEFAULT_GALLERY_SHAPE?.color as GalleryColor)
            }
          />
        </Form>

        <CardsSection
          containerRef={container}
          isLoading={isPageRecipientIdle || isPageRecipientsLoading}
          isSaveButtonLoading={isUpdatingPageRecipients}
          isViewingPublicly={isViewingPublicly}
          page={page}
          pageRecipientsEditData={pageRecipientsEditData}
          savedPageRecipients={pageRecipients}
          sectionStatus={getSectionStatus(SECTION_NAMES.CARDS_SECTION)}
          setPageRecipientsEditData={setPageRecipientsEditData}
          setSectionStatus={createSectionStatusSetter(
            SECTION_NAMES.CARDS_SECTION
          )}
          setVisibleTypes={setVisibleTypes}
          visibleTypes={visibleTypes}
          onSave={saveCardsSectionChanges}
        />

        <FooterSection
          themeColor={
            currentThemeColor ?? (DEFAULT_GALLERY_SHAPE?.color as GalleryColor)
          }
        />
      </S.Container>

      {accentColorPicker}
      {stickyBottomBadge}
    </>
  );
}

type StickyHeaderProps = {
  galleryTitleRef: React.RefObject<HTMLElement>;
  privacyStatusContent: React.ReactNode;
  showPrivacyStatus: boolean;
  title: string;
};

function StickyHeader(props: StickyHeaderProps) {
  const { galleryTitleRef, privacyStatusContent, showPrivacyStatus, title } =
    props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const visibilityObserver = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      { threshold: 0, rootMargin: '-60px 0px 0px 0px' }
    );

    if (galleryTitleRef.current) {
      visibilityObserver.observe(galleryTitleRef.current);
    }

    return () => visibilityObserver.disconnect();
  }, [galleryTitleRef]);

  return (
    <>
      <S.SlidingHeaderWrapper $isVisible={isVisible}>
        <S.SlidingHeaderText>{title}</S.SlidingHeaderText>
      </S.SlidingHeaderWrapper>
      {showPrivacyStatus && (
        <S.AnimatedPrivacyContainer $headerVisible={isVisible}>
          {privacyStatusContent}
        </S.AnimatedPrivacyContainer>
      )}
    </>
  );
}

export default DonorGalleryV2;
