import { ReactNode } from 'react';

export enum STATUS_OPTIONS {
  EDITING_WITH_CHANGES = 'EDITING_WITH_CHANGES',
  EDITING_WITH_NO_CHANGES = 'EDITING_WITH_NO_CHANGES',
  VIEW_MODE = 'VIEW_MODE',
}

export enum SECTION_NAMES {
  INTRO_SECTION = 'INTRO_SECTION',
  FOOTER_SECTION = 'FOOTER_SECTION',
  ADJECTIVE_SECTION = 'ADJECTIVE_SECTION',
  CARDS_SECTION = 'CARDS_SECTION',
  THEME = 'THEME',
}

export type SectionStatus = {
  name: SECTION_NAMES;
  status: STATUS_OPTIONS;
};

export type PrivacyOption = {
  value: string;
  label: ReactNode;
  description: string;
  icon: ReactNode;
};
