import React, { useState } from 'react';

import { useQueryClient } from 'react-query';

import { Button, Flex } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';

import {
  useBulkDeleteFilesMutation,
  useUpdatePageMutation,
} from 'src/client/hooks/mutations';
import {
  GET_GALLERY_CUSTOM_IMAGES_KEY,
  GET_PAGE_KEY,
  useGetGalleryCustomImageUrls,
} from 'src/client/hooks/queries';

import * as S from './styles';

type Props = {
  pageId: string;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
};

function GalleryImageHistory(props: Props) {
  const { pageId, onCancel } = props;

  const queryClient = useQueryClient();
  const {
    data: previousUploads,
    isLoading: isPreviousUploadsLoading,
    isFetching: isPreviousUploadsFetching,
  } = useGetGalleryCustomImageUrls(pageId);

  const { mutateAsync: updatePage, isLoading: isUpdatingPage } =
    useUpdatePageMutation({
      onSuccess: (updatePayload) => {
        queryClient.invalidateQueries(GET_PAGE_KEY);
        queryClient.invalidateQueries(GET_GALLERY_CUSTOM_IMAGES_KEY, {
          refetchInactive: true,
        });
      },
    });

  const {
    mutateAsync: deleteImagesFromStorage,
    isLoading: isDeletingImagesFromStorage,
  } = useBulkDeleteFilesMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_GALLERY_CUSTOM_IMAGES_KEY);
    },
  });

  const isLoading =
    isPreviousUploadsLoading ||
    isPreviousUploadsFetching ||
    isUpdatingPage ||
    isDeletingImagesFromStorage;

  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  function handleSelection(index: number) {
    const newSelection = toggleSelection(selectedIndices, index);
    setSelectedIndices(newSelection);
  }

  async function handleSetImage(e: React.MouseEvent<HTMLElement>) {
    if (selectedIndices.length !== 1) {
      return;
    }

    if (previousUploads) {
      const filePath = new URL(previousUploads[selectedIndices[0]]).pathname
        .split('/')
        .slice(2)
        .join('/');

      await updatePage({
        id: pageId,
        galleryCustomImageFilePath: filePath,
      });
    }

    onCancel?.(e);
  }

  async function handleDelete() {
    if (previousUploads) {
      const filePaths = selectedIndices.map((index) => {
        const url = previousUploads[index];

        return new URL(url).pathname.split('/').slice(2).join('/');
      });

      await deleteImagesFromStorage(filePaths);
      setSelectedIndices([]);

      //TODO: Add feedback
    }
  }

  const imageHistory =
    previousUploads && previousUploads.length > 0 ? (
      previousUploads?.map((upload, index) => (
        <ImageItem
          image={upload}
          index={index}
          isLoading={isLoading}
          isSelected={selectedIndices.includes(index)}
          key={upload}
          onSelect={handleSelection}
        />
      ))
    ) : (
      <span>No uploads. Upload your own logo!</span>
    );

  if (isPreviousUploadsLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <S.GridContainer>
        {isPreviousUploadsFetching ? <LoadingAnimation /> : imageHistory}
      </S.GridContainer>

      <S.ButtonGroup>
        <S.DeleteButton
          disabled={
            selectedIndices.length === 0 ||
            isPreviousUploadsFetching ||
            isPreviousUploadsLoading ||
            isUpdatingPage
          }
          loading={isDeletingImagesFromStorage}
          onClick={handleDelete}
        >
          Delete selected
        </S.DeleteButton>
        <Flex gap="8px">
          <Button disabled={isLoading} type="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || selectedIndices.length !== 1}
            loading={isUpdatingPage}
            type="primary"
            onClick={handleSetImage}
          >
            Set as image
          </Button>
        </Flex>
      </S.ButtonGroup>
    </>
  );
}

function toggleSelection(current: number[], id: number): number[] {
  if (current.includes(id)) {
    return current.filter((item) => item !== id);
  }

  return [...current, id];
}

type ImageItemProps = {
  image: string;
  index: number;
  isLoading: boolean;
  isSelected: boolean;
  onSelect: (index: number) => void;
};

function ImageItem(props: ImageItemProps) {
  const { index, image, isLoading, isSelected, onSelect } = props;

  function handleSelect() {
    onSelect(index);
  }

  return (
    <S.CardWrapper>
      <S.ImageCard
        $isLoading={isLoading}
        $isSelected={isSelected}
        onClick={handleSelect}
      >
        <S.StyledImage
          alt={`Frame custom logo`}
          height={109}
          loading="lazy"
          src={image}
          width={109}
        />
      </S.ImageCard>
      <S.SelectLabel>
        <S.RadioInput
          checked={isSelected}
          type="radio"
          onChange={handleSelect}
        />
        <span>Select</span>
      </S.SelectLabel>
    </S.CardWrapper>
  );
}

export default GalleryImageHistory;
