import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FlexCenter, LoadingCard } from 'src/client/components';
import {
  MAX_PAGE_RECIPIENT_DISPLAY,
  RECIPIENT_CARD_WIDTH,
} from 'src/commons/constants/gallery';
import { PageRecipient } from 'src/commons/types';

import * as S from './styles';

type Props = {
  containerRef?: HTMLDivElement | null;
  unfeaturedPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
};

function UnfeaturedCardsViewMode(props: Props) {
  const { containerRef, unfeaturedPageRecipients, isLoading } = props;

  const [isAllPageRecipientsVisible, setIsAllPageRecipientsVisible] =
    useState(false);
  const cardsContainerRef = React.useRef<HTMLDivElement>(null);
  const [itemsPerRow, setItemsPerRow] = useState<number>(0);

  const calculateItemsPerRow = useCallback(() => {
    if (cardsContainerRef?.current) {
      const containerWidth = cardsContainerRef.current.offsetWidth;
      const items = Math.floor(containerWidth / RECIPIENT_CARD_WIDTH);
      setItemsPerRow(items);
    }
  }, []);

  useEffect(() => {
    calculateItemsPerRow();
    window.addEventListener('resize', calculateItemsPerRow);

    return () => {
      window.removeEventListener('resize', calculateItemsPerRow);
    };
  }, [calculateItemsPerRow]);

  const showAllPageRecipients = useCallback(() => {
    setIsAllPageRecipientsVisible(true);
  }, []);

  const scrollBackToTop = useCallback(() => {
    if (containerRef) {
      containerRef.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [containerRef]);

  const handleRecipientClick = useCallback((website: string) => {
    window.open('https://' + website, '_blank');
  }, []);

  const pageRecipientsToDisplay = useMemo(
    () =>
      isAllPageRecipientsVisible
        ? unfeaturedPageRecipients
        : unfeaturedPageRecipients?.slice(0, MAX_PAGE_RECIPIENT_DISPLAY),
    [unfeaturedPageRecipients, isAllPageRecipientsVisible]
  );

  const unfeaturedCards = useMemo(
    () =>
      pageRecipientsToDisplay?.map((pageRecipient) => (
        <S.StyledGiveCard
          data-page-recipient-visibility="unfeatured-card"
          key={pageRecipient.id}
          pageRecipient={pageRecipient}
          onClick={
            pageRecipient.recipientWebsite
              ? () => handleRecipientClick(pageRecipient.recipientWebsite!)
              : undefined
          }
        />
      )),
    [handleRecipientClick, pageRecipientsToDisplay]
  );

  const visibilityControlButton = useMemo(
    () =>
      isAllPageRecipientsVisible ? (
        <S.BackToTopButton
          style={{ width: `${itemsPerRow * RECIPIENT_CARD_WIDTH}px` }}
          onClick={scrollBackToTop}
        >
          Back to top
        </S.BackToTopButton>
      ) : (
        <S.VisibilityControlButton
          data-cy="load-more-btn"
          style={{ width: `${itemsPerRow * RECIPIENT_CARD_WIDTH}px` }}
          onClick={showAllPageRecipients}
        >
          Load more
        </S.VisibilityControlButton>
      ),
    [
      isAllPageRecipientsVisible,
      itemsPerRow,
      scrollBackToTop,
      showAllPageRecipients,
    ]
  );

  return (
    <>
      <S.CardsContainer ref={cardsContainerRef}>
        <LoadingCard
          isLoading={isLoading}
          spinProps={{
            'data-cy': 'unfeatured-cards-spinner',
          }}
        >
          {unfeaturedCards}
        </LoadingCard>
      </S.CardsContainer>
      <FlexCenter>{visibilityControlButton}</FlexCenter>
    </>
  );
}

export default UnfeaturedCardsViewMode;
