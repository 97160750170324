import {
  DndContext,
  DragEndEvent,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { forwardRef, useMemo } from 'react';

import { LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import GiveCardV2 from '../../../DonorGalleryV2/components/CardsSection/components/GiveCard';
import GiveCardBase from '../GiveCard';

import * as stylesheet from './styles';

type Props = {
  isLoading: boolean;
  hiddenPageRecipients: PageRecipient[] | undefined;
  onFeaturePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
  showSpotlightText?: boolean;
  styles?: any;
};

const HiddenCardsEditMode = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    isLoading,
    hiddenPageRecipients,
    onFeaturePageRecipient,
    sensors,
    handleDragEnd,
    handleDragStart,
    showSpotlightText,
    styles,
  } = props;

  const S = styles ?? stylesheet;
  const GiveCard = styles ? GiveCardV2 : GiveCardBase;

  const screens = useBreakpoint();

  const hiddenCards = useMemo(
    () =>
      hiddenPageRecipients?.map((pageRecipient) => (
        <GiveCard
          isEditMode
          editModeConfig={{
            primaryButtonText: 'Show',
            isSecondaryButtonHidden: true,
            primaryButtonProps: {
              onClick: () => onFeaturePageRecipient(pageRecipient.id),
            },
          }}
          isDraggable={false}
          key={pageRecipient.id}
          pageRecipient={pageRecipient}
        />
      )),
    [GiveCard, hiddenPageRecipients, onFeaturePageRecipient]
  );

  const instructionText = useMemo(
    () => (
      <div>
        To prevent people from seeing specific recipients,{' '}
        {screens.lg ? 'hover' : 'tap'} over them and click <span>Hide</span>
      </div>
    ),
    [screens.lg]
  );

  const spotlightText = useMemo(
    () =>
      styles &&
      showSpotlightText && (
        <S.SpotlightText type="body1reg2">
          To prevent people from seeing specific recipients, hover over them and
          click Hide.
        </S.SpotlightText>
      ),
    [S, showSpotlightText, styles]
  );

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <S.StyledDragAndDropArea
        data-cy="hidden-cards-drag-and-drop-area"
        emptyStateInstructionText={instructionText}
        instructionTabText="Recipients in this section will not be visible when gallery is published to the public"
        isEmpty={!hiddenPageRecipients?.length}
        ref={ref}
      >
        {spotlightText}
        <LoadingCard isLoading={isLoading}>
          <S.HiddenCardsContainer>{hiddenCards}</S.HiddenCardsContainer>
        </LoadingCard>
      </S.StyledDragAndDropArea>
    </DndContext>
  );
});

export default HiddenCardsEditMode;
