import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import QuotesDrawer, {
  QuoteObject,
} from 'src/client/pages/DonorGallery/components/IntroSection/components/QuotesDrawer';
import { SectionStatus, STATUS_OPTIONS } from 'src/client/types/Gallery';
import {
  QUOTE_TEXT_AREA_ROWS_MAX,
  SET_QUOTE_DELAY,
} from 'src/commons/constants/gallery';
import { EMAIL_QUOTES } from 'src/commons/constants/quotes';
import { Page } from 'src/commons/types';

import { secureRandomNumber } from 'src/commons/utils/NumberUtils';

import QuotesButtonGroup from './components/QuotesButtonGroup';
import * as S from './styles';

type Props = {
  adjectiveSectionForm: FormInstance<any>;
  introSectionForm: FormInstance<any>;
  isViewingPublicly: boolean;
  page: Page;
  sectionStatus: SectionStatus;
  handleFormChange: () => void;
};

function GalleryQuoteAuthorInput(props: Props) {
  const {
    adjectiveSectionForm,
    introSectionForm,
    isViewingPublicly,
    page,
    sectionStatus,
    handleFormChange,
  } = props;

  const screens = useBreakpoint();

  const isAdjectiveSectionVisible = adjectiveSectionForm.getFieldValue(
    'isAdjectiveSectionVisible'
  );

  const isEditing = !isViewingPublicly;
  const isQuoteEmptyAndViewingPublicly = !page.quote && isViewingPublicly;
  const isAuthorVisible = useMemo(
    () => (isViewingPublicly ? !!page.author : true),
    [isViewingPublicly, page.author]
  ); // author field should always be hidden when empty in preview mode, even when viewing privately

  const isQuoteAuthorVisible = introSectionForm.getFieldValue(
    'isQuoteAuthorVisible'
  );
  const [isHidden, setIsHidden] = useState<boolean>(!isQuoteAuthorVisible);
  const [open, setOpen] = useState(false);
  const [isSetQuoteLoading, setIsSetQuoteLoading] = useState(false);

  useEffect(() => {
    if (sectionStatus.status === STATUS_OPTIONS.VIEW_MODE) {
      setIsHidden(!isQuoteAuthorVisible); // reset error state when form is reset
    }
  }, [isQuoteAuthorVisible, sectionStatus]);

  const handleSetQuoteAuthorVisibility = useCallback(
    (value: boolean) => {
      introSectionForm.setFieldsValue({ isQuoteAuthorVisible: !value });
      setIsHidden(value);
      handleFormChange();
    },
    [introSectionForm, handleFormChange]
  );

  const handleSeeAllQuotes = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleSelectQuote = useCallback(
    (quote: QuoteObject) => {
      const currentValues = introSectionForm.getFieldsValue();

      if (
        quote.quote === currentValues.quote &&
        quote.author === currentValues.author
      ) {
        return;
      }

      setIsSetQuoteLoading(true);
      setTimeout(() => {
        setIsSetQuoteLoading(false);
      }, SET_QUOTE_DELAY);
      introSectionForm.setFieldsValue({ ...quote });
      handleFormChange();
    },
    [introSectionForm, handleFormChange]
  );

  const handleRandomizeQuote = useCallback(() => {
    const randomIndex = secureRandomNumber(EMAIL_QUOTES.length);
    const randomQuote = EMAIL_QUOTES[randomIndex];
    handleSelectQuote({
      author: randomQuote.author,
      quote: randomQuote.message,
    });
  }, [handleSelectQuote]);

  const quoteTextArea = useMemo(
    () => (
      <S.QuoteTextInputContainer>
        {!isViewingPublicly && (
          <S.StyledEditLabel isHidden={isHidden}>
            Quote that inspires you
          </S.StyledEditLabel>
        )}

        <S.InputContainer isEditing={isEditing} isHidden={isHidden}>
          {!isSetQuoteLoading ? (
            <>
              {!isQuoteEmptyAndViewingPublicly && (
                <Form.Item noStyle name="quote">
                  <S.QuoteTextArea
                    autoSize={{
                      minRows: screens.xxl ? QUOTE_TEXT_AREA_ROWS_MAX : 2,
                    }}
                    data-cy="quote-text-area"
                    isEditing={isEditing}
                    key={`${String(isEditing)}-quote`}
                    name="quote"
                    placeholder={`Add a quote that inspires your Giving Side`}
                  />
                </Form.Item>
              )}
              {isAuthorVisible && (
                <S.AuthorContainer>
                  <Form.Item noStyle name="author">
                    <S.AuthorTextArea
                      autoSize={{ maxRows: 2 }}
                      data-cy="author-text-area"
                      isAdjectiveSectionVisible={isAdjectiveSectionVisible}
                      isEditing={isEditing}
                      key={`${String(isEditing)}-author`}
                      name="author"
                      placeholder={'Add Author'}
                    />
                  </Form.Item>
                </S.AuthorContainer>
              )}
            </>
          ) : (
            <S.StyledSpinnerContainer>
              <LoadingAnimation size="large" />
            </S.StyledSpinnerContainer>
          )}

          {isEditing && (
            <QuotesButtonGroup
              onRandomizeQuote={handleRandomizeQuote}
              onSeeAllQuotes={handleSeeAllQuotes}
            />
          )}
        </S.InputContainer>

        {!isViewingPublicly && (
          <Form.Item noStyle name="isQuoteAuthorVisible">
            <S.OptionsGroup isHidden={isHidden}>
              <Button
                type="noStyle"
                onClick={() => handleSetQuoteAuthorVisibility(false)}
              >
                <EyeOutlined height={20} />
                <span>Show</span>
              </Button>
              <Button
                type="noStyle"
                onClick={() => handleSetQuoteAuthorVisibility(true)}
              >
                <EyeInvisibleOutlined />
                <span>Hide</span>
              </Button>
            </S.OptionsGroup>
          </Form.Item>
        )}
      </S.QuoteTextInputContainer>
    ),
    [
      handleRandomizeQuote,
      handleSeeAllQuotes,
      handleSetQuoteAuthorVisibility,
      isAdjectiveSectionVisible,
      isAuthorVisible,
      isEditing,
      isHidden,
      isQuoteEmptyAndViewingPublicly,
      isSetQuoteLoading,
      isViewingPublicly,
      screens.xxl,
    ]
  );

  if (isViewingPublicly && isHidden) {
    return null;
  }

  return (
    <>
      <S.Container>{quoteTextArea}</S.Container>
      <QuotesDrawer
        open={open}
        selectedQuote={{
          author: introSectionForm.getFieldValue('author'),
          quote: introSectionForm.getFieldValue('quote'),
        }}
        onClose={() => setOpen(false)}
        onSelectQuote={handleSelectQuote}
      />
    </>
  );
}

export default GalleryQuoteAuthorInput;
