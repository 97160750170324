import { LinkOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

import { useTheme } from 'styled-components';

import { ReactComponent as Lock } from 'src/client/assets/images/lock.svg';
import { ReactComponent as Sparkles } from 'src/client/assets/images/sparkles.svg';
import { Box, Button, Flex, FlexCenter, Text } from 'src/client/components';

import * as S from './styles';

type Props = React.ComponentProps<typeof Modal> & {
  pageVisibility: boolean;
  publicLinkDisplayText: string;
  handleCopyPublicLink: () => void;
  onClose: () => void;
};

function VisibilityChangeSuccessModal(props: Props) {
  const {
    pageVisibility,
    publicLinkDisplayText,
    handleCopyPublicLink,
    onClose,
    ...modalProps
  } = props;

  const { colors } = useTheme();

  const isPrivate = !pageVisibility;

  const gallerySharedBody = (
    <>
      <Sparkles />
      <Box margin="0 0 24px" />
      <Text type="h4med2">You can now share your Gallery!</Text>
      <Text color={colors.darkEmphasis2} type="body2reg2">
        Anyone with the link can view.
      </Text>
    </>
  );

  const galleryHiddenBody = (
    <>
      <Lock />
      <Box margin="0 0 24px" />
      <Text type="h4med2">Your gallery is now private.</Text>
      <Text color={colors.darkEmphasis2} type="body2reg2">
        Only you can view your gallery.
      </Text>
    </>
  );

  const galleryHiddenActionButtonGroup = (
    <FlexCenter>
      <Button type="secondary" onClick={onClose}>
        Done
      </Button>
    </FlexCenter>
  );

  const gallerySharedActionButtonGroup = (
    <S.SuccessActionButtonGroupContainer>
      <S.PublicLinkDisplay>{publicLinkDisplayText}</S.PublicLinkDisplay>
      <Button type="primary" onClick={handleCopyPublicLink}>
        <LinkOutlined />
        Copy link
      </Button>
    </S.SuccessActionButtonGroupContainer>
  );

  return (
    <S.StyledModal {...modalProps} centered footer={false}>
      <Flex alignItems="center" flexDirection="column">
        {isPrivate ? galleryHiddenBody : gallerySharedBody}
        <Box margin="0 0 24px" />
      </Flex>
      {isPrivate
        ? galleryHiddenActionButtonGroup
        : gallerySharedActionButtonGroup}
    </S.StyledModal>
  );
}

export default VisibilityChangeSuccessModal;
